import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "./ContentPage.css";

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [bannerToDelete, setBannerToDelete] = useState(null);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const bannerCollection = await firebase
          .firestore()
          .collection("bannerImages")
          .get();
        const bannerList = bannerCollection.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBanners(bannerList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching banners: ", error);
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  const handleDelete = async () => {
    if (bannerToDelete) {
      try {
        await firebase
          .firestore()
          .collection("bannerImages")
          .doc(bannerToDelete)
          .delete();
        setBanners(banners.filter((banner) => banner.id !== bannerToDelete));
        closeModal();
      } catch (error) {
        console.error("Error deleting banner: ", error);
      }
    }
  };

  const openModal = (id) => {
    setBannerToDelete(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setBannerToDelete(null);
  };

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  return (
    <>
      <div className="headingContainer">
        <p className="heading">Banners</p>
      </div>
      <div className="bannersContainer">
        <div className="bannersList">
          {banners.map((banner) => (
            <div key={banner.id} className="bannerItem">
              <img src={banner.imageUrl} alt="Banner" className="bannerImage" />
              <p className="bannerPage">
                <strong style={{ fontWeight: "500" }}>Page:</strong>{" "}
                {banner.page}
              </p>
              <button
                className="deleteButton"
                onClick={() => openModal(banner.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <div className="popup">
          <div className="popupContent">
            <p>Are you sure you want to delete this banner?</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <button onClick={handleDelete}>Yes</button>
              <button onClick={closeModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banners;
