import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./UsersScreen.css";
import Loader from "./Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faBriefcase, faBirthdayCake, faTrashAlt, faEdit, faMoneyBillAlt, faSearch, faCalendar } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "./ConfirmationModal";
import userIcon from "./Assets/user-icon.png";

const UsersScreen = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState({ show: false, user: null });
  const [editModalState, setEditModalState] = useState({ show: false, user: null });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const snapshot = await firebase.firestore().collection("users").get();
        const usersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
        setFilteredUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users: ", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users.filter((user) =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) || user.email?.toLowerCase().includes(searchTerm.toLowerCase()) || user.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, users]);

  const handleDelete = (user) => {
    setModalState({ show: true, user });
  };

  const confirmDelete = async () => {
    try {
      await firebase.firestore().collection("users").doc(modalState.user.id).delete();
      setUsers((prev) => prev.filter((item) => item.id !== modalState.user.id));
      setFilteredUsers((prev) => prev.filter((item) => item.id !== modalState.user.id));
      setModalState({ show: false, user: null });
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, user: null });
  };

  const handleEdit = (user) => {
    setEditModalState({ show: true, user });
  };

  const closeEditModal = () => {
    setEditModalState({ show: false, user: null });
  };

  const handleEditSubmit = async (updatedUser) => {
    try {
      // Convert balance to a number before submitting
      updatedUser.balance = parseFloat(updatedUser.balance);
  
      await firebase.firestore().collection("users").doc(updatedUser.id).update(updatedUser);
      
      setUsers((prev) =>
        prev.map((user) => (user.id === updatedUser.id ? updatedUser : user))
      );
      setFilteredUsers((prev) =>
        prev.map((user) => (user.id === updatedUser.id ? updatedUser : user))
      );
      setEditModalState({ show: false, user: null });
    } catch (error) {
      console.error("Error updating user: ", error);
    }
  };
  

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Users: {filteredUsers.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="users-screen">
        <div className="users-container">
          {filteredUsers.map((user) => (
            <div key={user.id} className="user-card">
              <img src={user.profileImage || userIcon} alt={user.name} className="profile-image" />
              <div className="user-details">
                <h2>{user.name}</h2>
                <p><FontAwesomeIcon icon={faEnvelope} /> {user.email}</p>
                <p><FontAwesomeIcon icon={faCalendar} /> {user.dob}</p>
                <p><FontAwesomeIcon icon={faBriefcase} /> {user.occupation}</p>
                <p><FontAwesomeIcon icon={faMoneyBillAlt} /> {user.balance}</p>
              </div>
              <div className="button-container">
                <button
                  className="button-edit"
                  onClick={() => handleEdit(user)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  className="button-delete"
                  onClick={() => handleDelete(user)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this user?`}
        />
      )}
      {editModalState.show && (
        <EditUserModal
          show={editModalState.show}
          onClose={closeEditModal}
          user={editModalState.user}
          onSubmit={handleEditSubmit}
        />
      )}
    </>
  );
};

const EditUserModal = ({ show, onClose, user, onSubmit }) => {
  const [updatedUser, setUpdatedUser] = useState({ ...user });

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Ensure that balance is being converted correctly on change
    const updatedValue = name === 'balance' ? parseFloat(value) : value;

    setUpdatedUser((prev) => ({ ...prev, [name]: updatedValue }));
  };

  const handleSubmit = () => {
    onSubmit(updatedUser);
  };

  if (!show) return null;

  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal">
        <h2>Edit User</h2>
        <div className="edit-modal-content">
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={updatedUser.name}
              onChange={handleChange}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={updatedUser.email}
              onChange={handleChange}
            />
          </label>
          <label>
            DOB:
            <input
              type="date"
              name="dob"
              value={updatedUser.dob}
              onChange={handleChange}
            />
          </label>
          <label>
            Occupation:
            <input
              type="text"
              name="occupation"
              value={updatedUser.occupation}
              onChange={handleChange}
            />
          </label>
          <label>
            Balance:
            <input
              type="number"
              name="balance"
              value={updatedUser.balance}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="edit-modal-buttons">
          <button onClick={handleSubmit} style={{ backgroundColor: '#4CAF50' }}>Save</button>
          <button onClick={onClose} style={{ backgroundColor: "#d9534f" }}>Cancel</button>
        </div>
      </div>
    </div>
  );
};


export default UsersScreen;
