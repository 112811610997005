import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "./ContentPage.css";

const NewsUpload = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState(""); // New state for category
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleImageClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let imageUrl = "";
    try {
      if (image) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`images/${image.name}`);
        await fileRef.put(image);
        imageUrl = await fileRef.getDownloadURL();
      }

      // Add the document to Firestore
      const docRef = await firebase.firestore().collection("newsandfeed").add({
        title,
        content,
        author,
        category, // Include category in the document
        imageUrl,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Get the document ID and update the document with its ID
      const docId = docRef.id;
      await firebase.firestore().collection("newsandfeed").doc(docId).update({
        id: docId,
      });

      setLoading(false);
      setMessage("News uploaded successfully!");
      setShowPopup(true);
      clearForm();
    } catch (error) {
      setLoading(false);
      setMessage("News not uploaded. Please try again.");
      setShowPopup(true);
    }
  };

  const clearForm = () => {
    setTitle("");
    setContent("");
    setAuthor("");
    setCategory(""); // Clear category
    setImage(null);
    setImagePreview(null);
  };

  const closePopup = () => {
    setShowPopup(false);
    setMessage("");
  };

  return (
    <>
      <div className="headingContainer">
        <p className="heading">News and Feeds</p>
      </div>
      <div className="uploadContainer">
        <form onSubmit={handleSubmit} className="uploadForm">
          <label>Title</label>
          <input
            type="text"
            value={title}
            placeholder="e.g. Doctrine Of Merger Not Universal In Application, Article 142 Powers An Exception..."
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <label>Content</label>
          <textarea
            className="NewsContent"
            value={content}
            placeholder="e.g. While ruling in favor of the Delhi government and its entities in a batch of land acquisition cases, the Supreme Court recently observed that the doctrine of merger is not of universal application and powers under Article 142 of the Constitution shall be deemed as an exception thereto..."
            onChange={(e) => setContent(e.target.value)}
            required
          ></textarea>
          <label>Author</label>
          <input
            type="text"
            value={author}
            placeholder="e.g. John"
            onChange={(e) => setAuthor(e.target.value)}
            required
          />
          <label>Category</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
            style={{height: 40, border: '1px solid #ccc',
              borderRadius: '4px', paddingLeft: 5, marginBottom: 10}}
          >
            <option value="" disabled>Select a category</option>
            <option value="Legal">Legal</option>
            <option value="Court">Court</option>
            <option value="Education">Education</option>
            <option value="Politics">Politics</option>
            <option value="Health">Health</option>
            <option value="Technology">Technology</option>
          </select>
          <label>Image</label>
          <div
            className="imageUploadContainer"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          >
            <input
              id="imageInput"
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <button type="button">{image ? image.name : "Upload Image"}</button>
          </div>
          {imagePreview && (
            <div className="imagePreviewContainer">
              <img src={imagePreview} alt="Selected" className="imagePreview" />
            </div>
          )}
          <button type="submit" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </button>
        </form>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popupContent">
            <p>{message}</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default NewsUpload;
