import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "./AdminPanel.css";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";

// Initialize Firebase if not already initialized
if (!firebase.apps.length) {
  const firebaseConfig = {
    apiKey: "AIzaSyAnNZFV6k-9ZpokQR_d5syekQU9JgHQEa8",
    authDomain: "lexdo-7b0b1.firebaseapp.com",
    projectId: "lexdo-7b0b1",
    storageBucket: "lexdo-7b0b1.appspot.com",
    messagingSenderId: "323278730681",
    appId: "1:323278730681:web:82a5e0300cea57b897f268",
    measurementId: "G-5VQFBFWKDZ",
  };
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firestore.collection("users").doc(userId).get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
      phoneNumber: userData?.phoneNumber || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "", phoneNumber: "" };
  }
};

const sendNotification = async (userId, message) => {
  try {
    await firestore.collection("approvalNotifications").add({
      userId,
      message,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    console.error("Error sending notification: ", error);
  }
};

const AdminPanel = ({ selectedTab }) => {
  const [pendingItems, setPendingItems] = useState([]);
  const [updatedItems, setUpdatedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    action: null,
    item: null,
  });

  useEffect(() => {
    const collectionMap = {
      "Pending Approvals": [
        "pendingVideos",
        "pendingBlogs",
        "pendingNews",
        "pendingLawyers",
        "pendingAuditors",
        "pendingConsultants",
        "pendingLawFirms",
        "pendingAuditFirms",
        "pendingPrivateDetectives",
        "pendingEvents",
        "pendingOpportunities",
      ],
      Blogs: ["pendingBlogs"],
      News: ["pendingNews"],
      Reels: ["pendingReels"],
      MyLawyer: ["pendingLawyers"],
      MyAuditor: ["pendingAuditors"],
      MyConsultant: ["pendingConsultants"],
      LawFirm: ["pendingLawFirms"],
      AuditFirm: ["pendingAuditFirms"],
      PrivateDetective: ["pendingPrivateDetectives"],
      Events: ["pendingEvents"],
      Opportunity: ["pendingOpportunities"],
    };

    const collections = collectionMap[selectedTab];
    if (!collections) return;

    const unsubscribeFunctions = [];

    const fetchPendingItems = () => {
      try {
        collections.forEach((col) => {
          const unsubscribe = firestore
            .collection(col)
            .orderBy("createdAt", "desc")
            .onSnapshot(async (snapshot) => {
              const events = snapshot.docs.map((doc) => ({
                id: doc.id,
                type: col.replace("pending", "").toLowerCase(),
                ...doc.data(),
              }));

              const userIds = events.map((event) => event.userId);
              const userDetailsPromises = userIds.map((userId) =>
                fetchUserNameAndImage(userId)
              );
              const fetchedUserDetails = await Promise.all(userDetailsPromises);

              const userDetailsMap = userIds.reduce((acc, userId, index) => {
                acc[userId] = fetchedUserDetails[index];
                return acc;
              }, {});

              setUserDetails((prevDetails) => ({
                ...prevDetails,
                ...userDetailsMap,
              }));
              setPendingItems((prevItems) => [
                ...prevItems.filter((item) => item.type !== col.replace("pending", "").toLowerCase()),
                ...events,
              ]);
            });

          unsubscribeFunctions.push(unsubscribe);
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching pending items: ", error);
        setLoading(false);
      }
    };

    const fetchUpdatedItems = () => {
      try {
        const collectionMap = [
          "updateLawyers",
          "updateAuditors",
          "updateConsultants",
          "updateLawFirms",
          "updateAuditfirms",
          "updatePrivateDetectives",
          "updateOpportunities",
          "updateEvents",
        ];

        collectionMap.forEach((col) => {
          const unsubscribe = firestore
            .collection(col)
            .orderBy("updatedAt", "desc")
            .onSnapshot(async (snapshot) => {
              const events = snapshot.docs.map((doc) => ({
                id: doc.id,
                type: col.replace("update", "").toLowerCase(),
                ...doc.data(),
                source: col,
              }));

              const userIds = events.map((event) => event.userId);
              const userDetailsPromises = userIds.map((userId) =>
                fetchUserNameAndImage(userId)
              );
              const fetchedUserDetails = await Promise.all(userDetailsPromises);

              const userDetailsMap = userIds.reduce((acc, userId, index) => {
                acc[userId] = fetchedUserDetails[index];
                return acc;
              }, {});

              setUserDetails((prevDetails) => ({
                ...prevDetails,
                ...userDetailsMap,
              }));
              setUpdatedItems((prevItems) => [
                ...prevItems.filter((item) => item.type !== col.replace("update", "").toLowerCase()),
                ...events,
              ]);
            });

          unsubscribeFunctions.push(unsubscribe);
        });
      } catch (error) {
        console.error("Error fetching updated items: ", error);
      }
    };

    fetchPendingItems();
    fetchUpdatedItems();

    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }, [selectedTab]);

  const handleApprove = async (item) => {
    setModalState({
      show: true,
      action: "approve",
      item,
    });
  };

  const handleReject = async (item) => {
    setModalState({
      show: true,
      action: "reject",
      item,
    });
  };

  const confirmAction = async () => {
    const { action, item } = modalState;
    setModalState({ show: false, action: null, item: null });

    if (action === "approve") {
      await approveItem(item);
    } else if (action === "reject") {
      await rejectItem(item);
    }
  };

  const displayNameMap = {
    videos: "Byte video",
    blogs: "Blog post",
    news: "News post",
    lawyers: "Lawyer profile",
    auditors: "Auditor profile",
    consultants: "consultant profile",
    lawfirms: "Law Firm profile",
    auditfirms: "Audit Firm profile",
    privatedetectives: "Private Detective profile",
    events: "Event post",
    opportunities: "Opportunity post",
  };

  const approveItem = async (item) => {
    try {
      const { id, type, userId, source } = item; // Added source here
      const collectionMap = {
        videos: "approvedVideos",
        blogs: "approvedBlogs",
        news: "newsandfeed",
        lawyers: "approvedLawyers",
        auditors: "approvedAuditors",
        consultants: "approvedConsultants",
        lawfirms: "approvedLawFirms",
        auditfirms: "approvedAuditFirms",
        privatedetectives: "approvedPrivateDetectives",
        events: "approvedEvents",
        opportunities: "approvedOpportunities",
      };
      const pendingCollectionMap = {
        videos: "pendingVideos",
        blogs: "pendingBlogs",
        news: "pendingNews",
        lawyers: "pendingLawyers",
        auditors: "pendingAuditors",
        consultants: "pendingConsultants",
        lawfirms: "pendingLawFirms",
        auditfirms: "pendingAuditFirms",
        privatedetectives: "pendingPrivateDetectives",
        events: "pendingEvents",
        opportunities: "pendingOpportunities",
      };
  
      const isPendingItem = pendingItems.some(
        (pendingItem) => pendingItem.id === id
      );
  
      if (source) {
        await firestore.collection(collectionMap[type]).doc(id).set(item);
        await firestore.collection(source).doc(id).delete();
      } else {
        await firestore.collection(collectionMap[type]).doc(id).set(item);
        await firestore.collection(pendingCollectionMap[type]).doc(id).delete();
      }
  
      // Update the user's name in the users collection if needed
      const userDocRef = firestore.collection("users").doc(userId);
  
      if (
        ["lawyers", "auditors", "consultants", "lawfirms", "auditfirms", "privatedetectives"].includes(type)
      ) {
        const newName = item.fullName || item.firmName;
        await userDocRef.update({ name: newName });
      }
  
      setPendingItems((prevItems) =>
        prevItems.filter((pendingItem) => pendingItem.id !== id)
      );
      setUpdatedItems((prevItems) =>
        prevItems.filter((updatedItem) => updatedItem.id !== id)
      );
  
      const displayName = displayNameMap[type];
  
      // Send notification to the user
      const notificationMessage = isPendingItem
        ? `${displayName} has been approved successfully.`
        : `${displayName} has been updated successfully.`;
      await sendNotification(userId, notificationMessage);
  
      window.alert(notificationMessage);
    } catch (error) {
      console.error("Error approving item: ", error);
      window.alert("There was an error approving the item. Please try again.");
    }
  };
  

  const rejectItem = async (item) => {
    try {
      const { id, type, userId, source } = item; // Added source here
      const pendingCollectionMap = {
        videos: "pendingVideos",
        blogs: "pendingBlogs",
        news: "pendingNews",
        lawyers: "pendingLawyers",
        auditors: "pendingAuditors",
        consultants: "pendingConsultants",
        lawfirms: "pendingLawFirms",
        auditfirms: "pendingAuditFirms",
        privatedetectives: "pendingPrivateDetectives",
        events: "pendingEvents",
        opportunities: "pendingOpportunities",
      };
  
      const isPendingItem = pendingItems.some(
        (pendingItem) => pendingItem.id === id
      );
  
      if (source) {
        await firestore.collection(source).doc(id).delete();
      } else {
        await firestore.collection(pendingCollectionMap[type]).doc(id).delete();
      }
  
      setPendingItems((prevItems) =>
        prevItems.filter((pendingItem) => pendingItem.id !== id)
      );
      setUpdatedItems((prevItems) =>
        prevItems.filter((updatedItem) => updatedItem.id !== id)
      );
  
      const displayName = displayNameMap[type];
  
      // Send notification to the user
      const notificationMessage = isPendingItem
        ? `${displayName} has been rejected. Please review the submission guidelines and try again.`
        : `${displayName} update has been rejected. Please review the submission guidelines and try again.`;
      await sendNotification(userId, notificationMessage);
  
      window.alert(notificationMessage);
    } catch (error) {
      console.error("Error rejecting item: ", error);
      window.alert("There was an error rejecting the item. Please try again.");
    }
  };

  const renderItem = (item) => {
    const userDetail = userDetails[item.userId] || {};
    const itemClass =
      item.type === "blogs" || "news"
        ? "itemContainer blogItemContainer"
        : "itemContainer";

    const renderItemContent = () => {
      switch (item.type) {
        case "videos":
          return (
            <>
              <p className="ItemHeading">Reels</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                  style={{ width: 50, height: 50 }}
                />
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Caption:</p>
                <p className="detail">{item.caption}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Language:</p>
                <p className="detail">{item.language}</p>
              </div>
              <video src={item.videoUrl} className="video" controls />
            </>
          );
        case "blogs":
          return (
            <div className="blogContainer">
              <p className="ItemHeading">Blogs</p>
              <div className="userInfo">
                <img
                  src={item.imageUrl}
                  alt={item.author}
                  className="profileImage"
                />
              </div>
              <h3 className="title">{item.title}</h3>
              <p className="content">{item.content}</p>
              <div className="detail-container">
                <p className="label detail">Author:</p>
                <p className="detail">{item.author}</p>
              </div>
            </div>
          );
        case "news":
          return (
            <div className="blogContainer">
              <p className="ItemHeading">News and Feed</p>
              <div className="userInfo">
                <img
                  src={item.imageUrl}
                  alt={item.author}
                  className="profileImage"
                />
              </div>
              <h3 className="title">{item.title}</h3>
              <p className="content">{item.content}</p>
              <div className="detail-container">
                <p className="label detail">Author:</p>
                <p className="detail">{item.author}</p>
              </div>
            </div>
          );
        case "lawyers":
          return (
            <div className="lawyerContainer">
              <p className="ItemHeading">My Lawyer</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="label">User Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label">User Mobile:</p>
                <p className="detail">{userDetail.phoneNumber}</p>
              </div>
              <div className="detail-container">
                <p className="label">Lawyer Name:</p>
                <p className="detail">{item.fullName}</p>
              </div>
              <div className="detail-container">
                <p className="label">Email:</p>
                <p className="detail">{item.email}</p>
              </div>
              <div className="detail-container">
                <p className="label">Gender:</p>
                <p className="detail">{item.gender}</p>
              </div>
              <div className="detail-container">
                <p className="label">City:</p>
                <p className="detail">{item.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label">Enrollment ID:</p>
                <p className="detail">{item.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label">Experience:</p>
                <p className="detail">{item.experience} Years</p>
              </div>
              <div className="detail-container">
                <p className="label">Languages:</p>
                <p className="detail">{item.languages.join(", ")}</p>
              </div>
              <div
                className="detail-container"
                style={{ alignItems: "flex-start" }}
              >
                <p className="label">Specializations:</p>
                <p className="detail">{item.practiceAreas.join(", ")}</p>
              </div>
              <div className="detail-container">
                <p className="label">Selected Plan:</p>
                <p className="detail">
                  ₹ {item.selectedPlan} / {item.planDuration}
                </p>
              </div>
            </div>
          );
          case "consultants":
          return (
            <div className="lawyerContainer">
              <p className="ItemHeading">Consultants</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="label">User Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label">User Mobile:</p>
                <p className="detail">{userDetail.phoneNumber}</p>
              </div>
              <div className="detail-container">
                <p className="label">Lawyer Name:</p>
                <p className="detail">{item.fullName}</p>
              </div>
              <div className="detail-container">
                <p className="label">Email:</p>
                <p className="detail">{item.email}</p>
              </div>
              <div className="detail-container">
                <p className="label">Gender:</p>
                <p className="detail">{item.gender}</p>
              </div>
              <div className="detail-container">
                <p className="label">City:</p>
                <p className="detail">{item.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label">Enrollment ID:</p>
                <p className="detail">{item.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label">Experience:</p>
                <p className="detail">{item.experience} Years</p>
              </div>
              <div className="detail-container">
                <p className="label">Languages:</p>
                <p className="detail">{item.languages.join(", ")}</p>
              </div>
              <div
                className="detail-container"
                style={{ alignItems: "flex-start" }}
              >
                <p className="label">Specializations:</p>
                <p className="detail">{item.practiceAreas.join(", ")}</p>
              </div>
              <div className="detail-container">
                <p className="label">Selected Plan:</p>
                <p className="detail">
                  ₹ {item.selectedPlan} / {item.planDuration}
                </p>
              </div>
            </div>
          );
        case "auditors":
          return (
            <div className="lawyerContainer">
              <p className="ItemHeading">My Auditor</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="label">User Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label">User Mobile:</p>
                <p className="detail">{userDetail.phoneNumber}</p>
              </div>
              <div className="detail-container">
                <p className="label">Auditor Name:</p>
                <p className="detail">{item.fullName}</p>
              </div>
              <div className="detail-container">
                <p className="label">Email:</p>
                <p className="detail">{item.email}</p>
              </div>
              <div className="detail-container">
                <p className="label">Gender:</p>
                <p className="detail">{item.gender}</p>
              </div>
              <div className="detail-container">
                <p className="label">City:</p>
                <p className="detail">{item.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label">Enrollment ID:</p>
                <p className="detail">{item.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label">Experience:</p>
                <p className="detail">{item.experience} Years</p>
              </div>
              <div className="detail-container">
                <p className="label">Languages:</p>
                <p className="detail">{item.languages.join(", ")}</p>
              </div>
              <div
                className="detail-container"
                style={{ alignItems: "flex-start" }}
              >
                <p className="label">Specializations:</p>
                <p className="detail">{item.practiceAreas.join(", ")}</p>
              </div>
              <div className="detail-container">
                <p className="label">Selected Plan:</p>
                <p className="detail">
                  ₹ {item.selectedPlan} / {item.planDuration}
                </p>
              </div>
            </div>
          );
        case "lawfirms":
          return (
            <div className="lawyerContainer">
              <p className="ItemHeading">Law Firm</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="label">User Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label">User Mobile:</p>
                <p className="detail">{userDetail.phoneNumber}</p>
              </div>
              <div className="detail-container">
                <p className="label">Law Firm Name:</p>
                <p className="detail">{item.firmName}</p>
              </div>
              <div className="detail-container">
                <p className="label">Email:</p>
                <p className="detail">{item.email}</p>
              </div>
              <div className="detail-container">
                <p className="label">City:</p>
                <p className="detail">{item.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label">Enrollment ID:</p>
                <p className="detail">{item.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label">Experience:</p>
                <p className="detail">{item.totalExperience} Years</p>
              </div>
              <div className="detail-container">
                <p className="label">Team Size:</p>
                <p className="detail">{item.teamSize} Members</p>
              </div>
              <div className="detail-container">
                <p className="label">Languages:</p>
                <p className="detail">{item.languages.join(", ")}</p>
              </div>
              <div
                className="detail-container"
                style={{ alignItems: "flex-start" }}
              >
                <p className="label">Specializations:</p>
                <p className="detail">{item.practiceAreas.join(", ")}</p>
              </div>
              <div className="detail-container">
                <p className="label">Selected Plan:</p>
                <p className="detail">
                  ₹ {item.selectedPlan} / {item.planDuration}
                </p>
              </div>
            </div>
          );
        case "auditfirms":
          return (
            <div className="lawyerContainer">
              <p className="ItemHeading">Audit Firm</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="label">User Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label">User Mobile:</p>
                <p className="detail">{userDetail.phoneNumber}</p>
              </div>
              <div className="detail-container">
                <p className="label">Audit Firm Name:</p>
                <p className="detail">{item.firmName}</p>
              </div>
              <div className="detail-container">
                <p className="label">Email:</p>
                <p className="detail">{item.email}</p>
              </div>
              <div className="detail-container">
                <p className="label">City:</p>
                <p className="detail">{item.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label">Enrollment ID:</p>
                <p className="detail">{item.qualificationId}</p>
              </div>
              <div className="detail-container">
                <p className="label">Experience:</p>
                <p className="detail">{item.totalExperience} Years</p>
              </div>
              <div className="detail-container">
                <p className="label">Team Size:</p>
                <p className="detail">{item.teamSize} Members</p>
              </div>
              <div className="detail-container">
                <p className="label">Languages:</p>
                <p className="detail">{item.languages.join(", ")}</p>
              </div>
              <div
                className="detail-container"
                style={{ alignItems: "flex-start" }}
              >
                <p className="label">Specializations:</p>
                <p className="detail">{item.expertiseAreas.join(", ")}</p>
              </div>
              <div className="detail-container">
                <p className="label">Selected Plan:</p>
                <p className="detail">
                  ₹ {item.selectedPlan} / {item.planDuration}
                </p>
              </div>
            </div>
          );
        case "privatedetectives":
          return (
            <div className="lawyerContainer">
              <p className="ItemHeading">Private Detective</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="label">User Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label">User Mobile:</p>
                <p className="detail">{userDetail.phoneNumber}</p>
              </div>
              <div className="detail-container">
                <p className="label">Detective Name:</p>
                <p className="detail">{item.fullName}</p>
              </div>
              <div className="detail-container">
                <p className="label">Email:</p>
                <p className="detail">{item.email}</p>
              </div>
              <div className="detail-container">
                <p className="label">Gender:</p>
                <p className="detail">{item.gender}</p>
              </div>
              <div className="detail-container">
                <p className="label">City:</p>
                <p className="detail">{item.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label">Enrollment ID:</p>
                <p className="detail">{item.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label">Experience:</p>
                <p className="detail">{item.experience} Years</p>
              </div>
              <div className="detail-container">
                <p className="label">Languages:</p>
                <p className="detail">{item.languages.join(", ")}</p>
              </div>
              <div
                className="detail-container"
                style={{ alignItems: "flex-start" }}
              >
                <p className="label">Specializations:</p>
                <p className="detail">{item.practiceAreas.join(", ")}</p>
              </div>
              <div className="detail-container">
                <p className="label">Selected Plan:</p>
                <p className="detail">
                  ₹ {item.selectedPlan} / {item.planDuration}
                </p>
              </div>
            </div>
          );
        case "events":
          return (
            <div className="lawyerContainer">
              <p className="ItemHeading">Events</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="label">Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label">Event Title:</p>
                <p className="detail">{item.eventTitle}</p>
              </div>
              <div className="detail-container">
                <p className="label">Organizer:</p>
                <p className="detail">{item.organizer}</p>
              </div>
              <div className="detail-container">
                <p className="label">Mobile:</p>
                <p className="detail">{item.mobile}</p>
              </div>
              <div className="detail-container">
                <p className="label">Email:</p>
                <p className="detail">{item.email}</p>
              </div>
              <div className="detail-container">
                <p className="label">Location:</p>
                <p className="detail">{item.location}</p>
              </div>
              <div className="detail-container">
                <p className="label">Mode:</p>
                <p className="detail">{item.mode}</p>
              </div>

              <div className="detail-container">
                <p className="label">Entry Fee:</p>
                <p className="detail">{item.entryFee}</p>
              </div>
              <div className="detail-container">
              <p className=" label ">Duration:</p>
              <p className="detail">
                  {new Date(item.startDate?.seconds * 1000).toDateString()} to{" "}
                  {item.endDate
                    ? new Date(item.endDate.seconds * 1000).toDateString()
                    : "N/A"}
                </p>
              </div>
              <div className="detail-container">
                <p className=" label ">Time:</p>
                <p className="detail">
                  {new Date(item.startDate?.seconds * 1000).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}{" "}
                  -{" "}
                  {new Date(item.endDate?.seconds * 1000).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </p>
              </div>
              <div className="detail-container">
                <p className="label">About:</p>
                <p className="detail">{item.aboutEvent}</p>
              </div>
              <div className="detail-container">
                <p className="label">Fee Amount:</p>
                <p className="detail">₹ {item.feeAmount}</p>
              </div>
              <div className="detail-container">
                <p className="label">No of Days:</p>
                <p className="detail">{item.feeDays}</p>
              </div>
            </div>
          );

        case "opportunities":
          return (
            <div className="lawyerContainer">
              <p className="ItemHeading">Opportunities</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="label">Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className=" label ">Firm:</p>
                <p className="detail">{item.companyName}</p>
              </div>
              <div className="detail-container">
                <p className=" label ">Mobile:</p>
                <p className="detail">{item.mobile}</p>
              </div>
              <div className="detail-container">
                <p className=" label ">Email:</p>
                <p className="detail">{item.email}</p>
              </div>
              <div className="detail-container">
                <p className=" label ">Role:</p>
                <p className="detail">{item.role}</p>
              </div>
              <div className="detail-container">
                <p className=" label ">Location:</p>
                <p className="detail">{item.location}</p>
              </div>
              <div className="detail-container">
                <p className=" label ">Joining:</p>
                <p className="detail">{item.joining}</p>
              </div>
              <div className="detail-container">
                <p className=" label ">Shift:</p>
                <p className="detail">{item.shift}</p>
              </div>
              <div className="detail-container">
                <p className=" label ">Working Duration:</p>
                <p className="detail">
                  {new Date(item.startDate?.seconds * 1000).toDateString()} to{" "}
                  {item.endDate
                    ? new Date(item.endDate.seconds * 1000).toDateString()
                    : "N/A"}
                </p>
              </div>
              <div className="detail-container">
                <p className=" label ">Working Time:</p>
                <p className="detail">
                  {new Date(item.startTime?.seconds * 1000).toLocaleTimeString(
                    [],
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    }
                  )}{" "}
                  -{" "}
                  {new Date(item.endTime?.seconds * 1000).toLocaleTimeString(
                    [],
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    }
                  )}
                </p>
              </div>
              <div className="detail-container">
                <p className=" label ">Pay Type:</p>
                <p className="detail">{item.payType}</p>
              </div>
              {(item.payType === "Stipend" || item.payType === "Salary") && (
                <div className="detail-container">
                  <p className=" label ">Pay Amount:</p>
                  <p className="detail">{item.payAmount}</p>
                </div>
              )}
              <div className="detail-container">
                <p className=" label ">Preferred Candidate (Gender):</p>
                <p className="detail">{item.preferredGender}</p>
              </div>
              <div className="detail-container">
                <p className="detail">
                  <strong style={{ fontWeight: "500" }}>Who Can Apply: </strong>
                  {item.whoCanApply.join(", ")}
                </p>
              </div>
              <div className="detail-container">
                <p className="detail">
                  <strong style={{ fontWeight: "500" }}>Perks: </strong>
                  {item.perks.join(", ")}
                </p>
              </div>
              <div className="detail-container">
                <p className="detail">
                  <strong style={{ fontWeight: "500" }}>Description: </strong>
                  {item.content}
                </p>
              </div>
              <div className="detail-container">
                <p className="label">Number of Openings:</p>
                <p className="detail">{item.openings}</p>
              </div>
              <div className="detail-container">
                <p className="label">Fee Amount:</p>
                <p className="detail">₹ {item.feeAmount}</p>
              </div>
              <div className="detail-container">
                <p className="label">No of Days:</p>
                <p className="detail">{item.feeDays}</p>
              </div>
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <div className={itemClass} key={item.id}>
        {renderItemContent()}
        <div className="buttonContainer">
          <button onClick={() => handleApprove(item)} className="approveButton">
            Approve
          </button>
          <button onClick={() => handleReject(item)} className="rejectButton">
            Reject
          </button>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="headingContainer">
        <p className="heading">Pending Approvals</p>
      </div>
      <div className="container">
        {pendingItems.map((item) => renderItem(item))}
        {updatedItems.map((item) => renderItem(item))}
      </div>
      <ConfirmationModal
        show={modalState.show}
        onClose={() => setModalState({ show: false, action: null, item: null })}
        onConfirm={confirmAction}
        message={`Are you sure you want to ${modalState.action} this item?`}
      />
    </>
  );
};

export default AdminPanel;
