import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";  // Make sure to import Firebase Storage
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const VideosScreen = () => {
  const [approvedVideos, setApprovedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    video: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredVideos, setFilteredVideos] = useState([]);

  useEffect(() => {
    const fetchApprovedVideos = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedVideos")
          .orderBy("createdAt", "desc")
          .get();
        const videos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = videos.map((video) => video.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedVideos(videos);
        setFilteredVideos(videos);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved videos: ", error);
        setLoading(false);
      }
    };

    fetchApprovedVideos();
  }, []);

  useEffect(() => {
    setFilteredVideos(
      approvedVideos.filter((video) =>
        video.caption?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        video.language?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userDetails[video.userId]?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, approvedVideos, userDetails]);

  const handleDelete = (video) => {
    setModalState({ show: true, video });
  };

  const confirmDelete = async () => {
    const { video } = modalState;
    if (!video) return;

    try {
      // Reference to the video file in Firebase Storage
      const storageRef = firebase.storage().refFromURL(video.videoUrl);

      // Delete the video file from Firebase Storage
      await storageRef.delete();

      // Delete the video metadata from Firestore
      await firebase
        .firestore()
        .collection("approvedVideos")
        .doc(video.id)
        .delete();

      // Update the state to remove the video from the UI
      setApprovedVideos((prev) =>
        prev.filter((item) => item.id !== video.id)
      );
      setFilteredVideos((prev) =>
        prev.filter((item) => item.id !== video.id)
      );
      setModalState({ show: false, video: null });
    } catch (error) {
      console.error("Error deleting video: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, video: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Videos: {filteredVideos.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Videos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredVideos.map((video) => {
          const userDetail = userDetails[video.userId] || {};
          return (
            <div key={video.id} className="contentItemVideo">
              <div className="userInfoVideo">
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImageVideo"
                />
                <p className="name">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Caption:</p>
                <p className="detail">{video.caption}</p>
              </div>
              <div className="detail-container">
                <p className="name label detail">Language:</p>
                <p className="detail">{video.language}</p>
              </div>
              <video src={video.videoUrl} controls className="video" />
              <div className="buttonContainer">
                <button
                  className="delete-icon"
                  onClick={() => handleDelete(video)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this video?`}
        />
      )}
    </>
  );
};

export default VideosScreen;
