import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const DetailsModal = ({ show, onClose, auditor, userDetail }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h2>Auditor Details</h2>
        <div className="detail-container">
          <p className="label">Name:</p>
          <p className="detail">{userDetail.name}</p>
        </div>
        <div className="detail-container">
          <p className="label">City:</p>
          <p className="detail">{auditor.residentialAddress.city}</p>
        </div>
        <div className="detail-container">
          <p className="label">Enrollment ID:</p>
          <p className="detail">{auditor.enrollmentId}</p>
        </div>
        <div className="detail-container">
          <p className="label">Experience:</p>
          <p className="detail">{auditor.experience}</p>
        </div>
        <div className="detail-container">
          <p className="label">Email:</p>
          <p className="detail">{auditor.email}</p>
        </div>
        <div className="detail-container">
          <p className="label">Specializations:</p>
          <p className="detail">{auditor.practiceAreas.join(", ")}</p>
        </div>
        <div className="detail-container">
          <p className="label">Plan:</p>
          <p className="detail">
            ₹ {auditor.selectedPlan} / {auditor.planDuration}
          </p>
        </div>
        <button onClick={onClose} className="closeButton">
          Close
        </button>
      </div>
    </div>
  );
};

const AuditorsScreen = () => {
  const [approvedAuditors, setApprovedAuditors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    auditor: null,
  });
  const [detailsModalState, setDetailsModalState] = useState({
    show: false,
    auditor: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAuditors, setFilteredAuditors] = useState([]);

  useEffect(() => {
    const fetchApprovedAuditors = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedAuditors")
          .orderBy("createdAt", "desc")
          .get();
        const auditors = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch user details for each auditor
        const userIds = auditors.map((auditor) => auditor.userId);
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedAuditors(auditors);
        setFilteredAuditors(auditors);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved auditors: ", error);
        setLoading(false);
      }
    };

    fetchApprovedAuditors();
  }, []);

  useEffect(() => {
    setFilteredAuditors(
      approvedAuditors.filter((auditor) => {
        const userDetail = userDetails[auditor.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.residentialAddress.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.enrollmentId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.experience?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          auditor.practiceAreas.some((area) =>
            area.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      })
    );
  }, [searchTerm, approvedAuditors, userDetails]);

  const handleDelete = (auditor) => {
    setModalState({ show: true, auditor });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedAuditors")
        .doc(modalState.auditor.id)
        .delete();
      setApprovedAuditors((prev) =>
        prev.filter((item) => item.id !== modalState.auditor.id)
      );
      setFilteredAuditors((prev) =>
        prev.filter((item) => item.id !== modalState.auditor.id)
      );
      setModalState({ show: false, auditor: null });
    } catch (error) {
      console.error("Error deleting auditor: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, auditor: null });
  };

  const handleItemClick = (auditor) => {
    setDetailsModalState({ show: true, auditor });
  };

  const closeDetailsModal = () => {
    setDetailsModalState({ show: false, auditor: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Auditors: {filteredAuditors.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Auditors..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredAuditors.map((auditor) => {
          const userDetail = userDetails[auditor.userId] || {};
          return (
            <div
              key={auditor.id}
              className="contentItem"
              onClick={() => handleItemClick(auditor)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>
              <div className="detail-container">
                <p className="name label detail">Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">City:</p>
                <p className="detail">{auditor.residentialAddress.city}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Membership ID:</p>
                <p className="detail">{auditor.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Experience:</p>
                <p className="detail">{auditor.experience}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Plan:</p>
                <p className="detail">
                  ₹ {auditor.selectedPlan} / {auditor.planDuration}
                </p>
              </div>
              <div className="buttonContainer">
                <button
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click event from bubbling up to the parent
                    handleDelete(auditor);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this auditor?`}
        />
      )}
      {detailsModalState.show && (
        <DetailsModal
          show={detailsModalState.show}
          onClose={closeDetailsModal}
          auditor={detailsModalState.auditor}
          userDetail={userDetails[detailsModalState.auditor.userId] || {}}
        />
      )}
    </>
  );
};

export default AuditorsScreen;
