import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const fetchUserNameAndImage = async (userId) => {
  try {
    const userDocument = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();
    const userData = userDocument.data();
    return {
      name: userData?.name || "",
      profileImage: userData?.profileImage || "",
    };
  } catch (error) {
    console.error("Error fetching user details: ", error);
    return { name: "", profileImage: "" };
  }
};

const DetailsModal = ({ show, onClose, privateDetective, userDetail }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h2>Private Detective Details</h2>
        <div className="detail-container">
          <p className="label">Name:</p>
          <p className="detail">{userDetail.name}</p>
        </div>
        <div className="detail-container">
          <p className="label">City:</p>
          <p className="detail">{privateDetective.residentialAddress.city}</p>
        </div>
        <div className="detail-container">
          <p className="label">Experience:</p>
          <p className="detail">{privateDetective.experience}</p>
        </div>
        <div className="detail-container">
          <p className="label">Email:</p>
          <p className="detail">{privateDetective.email}</p>
        </div>
        <div className="detail-container">
          <p className="label">Enrollment ID:</p>
          <p className="detail">{privateDetective.enrollmentId}</p>
        </div>
        <div className="detail-container">
          <p className="label">Specializations:</p>
          <p className="detail">{privateDetective.practiceAreas.join(", ")}</p>
        </div>
        <div className="detail-container">
          <p className="label">Plan:</p>
          <p className="detail">
            ₹ {privateDetective.selectedPlan} / {privateDetective.planDuration}
          </p>
        </div>
        <button onClick={onClose} className="closeButton">
          Close
        </button>
      </div>
    </div>
  );
};

const PrivateDetectivesScreen = () => {
  const [approvedPrivateDetectives, setApprovedPrivateDetectives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [modalState, setModalState] = useState({
    show: false,
    privateDetective: null,
  });
  const [detailsModalState, setDetailsModalState] = useState({
    show: false,
    privateDetective: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPrivateDetectives, setFilteredPrivateDetectives] = useState([]);

  useEffect(() => {
    const fetchApprovedPrivateDetectives = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("approvedPrivateDetectives")
          .orderBy("createdAt", "desc")
          .get();
        const privateDetectives = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const userIds = privateDetectives.map(
          (privateDetective) => privateDetective.userId
        );
        const userDetailsPromises = userIds.map((userId) =>
          fetchUserNameAndImage(userId)
        );
        const fetchedUserDetails = await Promise.all(userDetailsPromises);

        const userDetailsMap = userIds.reduce((acc, userId, index) => {
          acc[userId] = fetchedUserDetails[index];
          return acc;
        }, {});

        setUserDetails(userDetailsMap);
        setApprovedPrivateDetectives(privateDetectives);
        setFilteredPrivateDetectives(privateDetectives);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved private detectives: ", error);
        setLoading(false);
      }
    };

    fetchApprovedPrivateDetectives();
  }, []);

  useEffect(() => {
    setFilteredPrivateDetectives(
      approvedPrivateDetectives.filter((privateDetective) => {
        const userDetail = userDetails[privateDetective.userId] || {};
        return (
          userDetail.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          privateDetective.residentialAddress.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          privateDetective.enrollmentId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          privateDetective.experience?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          privateDetective.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          privateDetective.practiceAreas.some((area) =>
            area.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      })
    );
  }, [searchTerm, approvedPrivateDetectives, userDetails]);

  const handleDelete = (privateDetective) => {
    setModalState({ show: true, privateDetective });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("approvedPrivateDetectives")
        .doc(modalState.privateDetective.id)
        .delete();
      setApprovedPrivateDetectives((prev) =>
        prev.filter((item) => item.id !== modalState.privateDetective.id)
      );
      setFilteredPrivateDetectives((prev) =>
        prev.filter((item) => item.id !== modalState.privateDetective.id)
      );
      setModalState({ show: false, privateDetective: null });
    } catch (error) {
      console.error("Error deleting private detective: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, privateDetective: null });
  };

  const handleItemClick = (privateDetective) => {
    setDetailsModalState({ show: true, privateDetective });
  };

  const closeDetailsModal = () => {
    setDetailsModalState({ show: false, privateDetective: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Private Detectives: {filteredPrivateDetectives.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Private Detectives..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainer">
        {filteredPrivateDetectives.map((privateDetective) => {
          const userDetail = userDetails[privateDetective.userId] || {};
          return (
            <div
              key={privateDetective.id}
              className="contentItem"
              onClick={() => handleItemClick(privateDetective)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={userDetail.profileImage}
                  alt={userDetail.name}
                  className="profileImage"
                />
              </div>

              <div className="detail-container">
                <p className="name label detail">Name:</p>
                <p className="detail">{userDetail.name}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">City:</p>
                <p className="detail">
                  {privateDetective.residentialAddress.city}
                </p>
              </div>
              <div className="detail-container">
                <p className="label detail">Enrollment ID:</p>
                <p className="detail">{privateDetective.enrollmentId}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Experience:</p>
                <p className="detail">{privateDetective.experience}</p>
              </div>
              <div className="detail-container">
                <p className="label detail">Plan:</p>
                <p className="detail">
                  ₹ {privateDetective.selectedPlan} /{" "}
                  {privateDetective.planDuration}
                </p>
              </div>
              <div className="buttonContainer">
                <button
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click event from bubbling up to the parent
                    handleDelete(privateDetective);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this private detective?`}
        />
      )}
      {detailsModalState.show && (
        <DetailsModal
          show={detailsModalState.show}
          onClose={closeDetailsModal}
          privateDetective={detailsModalState.privateDetective}
          userDetail={
            userDetails[detailsModalState.privateDetective.userId] || {}
          }
        />
      )}
    </>
  );
};

export default PrivateDetectivesScreen;
