import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import "./ContentPage.css";

const NewsScreen = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState({
    show: false,
    newsItem: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNews, setFilteredNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        console.log("Fetching news from Firestore...");
        const snapshot = await firebase
          .firestore()
          .collection("newsandfeed")
          .get(); // Removed orderBy for simplicity

        if (snapshot.empty) {
          console.log("No matching documents.");
          setLoading(false);
          return;
        }

        const newsData = snapshot.docs.map((doc) => {
          console.log("Document data:", doc.data());
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt.toDate().toLocaleDateString(), // Convert to string
          };
        });

        console.log("Fetched news data: ", newsData);
        setNews(newsData);
        setFilteredNews(newsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching news: ", error);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    setFilteredNews(
      news.filter((newsItem) =>
        newsItem.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        newsItem.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        newsItem.author?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, news]);

  const handleDelete = (newsItem) => {
    setModalState({ show: true, newsItem });
  };

  const confirmDelete = async () => {
    try {
      await firebase
        .firestore()
        .collection("newsandfeed")
        .doc(modalState.newsItem.id)
        .delete();
      setNews((prev) =>
        prev.filter((item) => item.id !== modalState.newsItem.id)
      );
      setFilteredNews((prev) =>
        prev.filter((item) => item.id !== modalState.newsItem.id)
      );
      setModalState({ show: false, newsItem: null });
    } catch (error) {
      console.error("Error deleting news item: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, newsItem: null });
  };

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total News: {filteredNews.length}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search News..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="contentContainerBlogs">
        {filteredNews.map((newsItem) => (
          <div key={newsItem.id} className="contentItemBlogs">
            <h3 className="title">{newsItem.title}</h3>
            <p>{newsItem.content}</p>
            <div
              className="userInfo"
              style={{
                flexDirection: "row",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <div>
                <img
                  src={newsItem.imageUrl}
                  alt={newsItem.title}
                  className="profileImageBlogs"
                />
              </div>
              <div>
                <p className="name" style={{ marginBottom: "0px" }}>
                  <strong style={{ fontWeight: "500" }}>Author: </strong>
                  {newsItem.author}
                </p>
                <p className="name">
                  <strong style={{ fontWeight: "500" }}>Date: </strong>
                  {newsItem.createdAt}
                </p>
              </div>
            </div>
            <div className="buttonContainer">
              <button
                className="delete-icon"
                onClick={() => handleDelete(newsItem)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this news item?`}
        />
      )}
    </>
  );
};

export default NewsScreen;
